<template>
  <div class="guide-page">
    <p class="title">引导页配置</p>
    <a-form>
      <a-form-item label="引导页开关">
        <a-switch v-model:checked="flag" checked-children="开" un-checked-children="关"></a-switch>
      </a-form-item>
      <a-form-item label="图片展示时间">
        <a-input-number
          v-model:value="time"
          :min="1"
          :max="10"
          :formatter="value => `${value}秒`"
          :parser="value => value.replace('秒', '')"
        ></a-input-number>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="save">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import api from '@/services';
import { message } from 'ant-design-vue';

export default defineComponent({
  setup(props, context) {
    const formState = reactive({
      flag: false,
      time: 1
    });

    onMounted(() => {
      getGuidePageConfig();
    });

    const getGuidePageConfig = async () => {
      const { success, data } = await api.getGuidePageConfig();
      if (success) {
        formState.flag = data.flag ? 1 : 0;
        formState.time = data.time;
      }
    };

    const save = async () => {
      let params = {
        time: formState.time,
        flag: formState.flag ? 1 : 0
      };
      const { success } = await api.saveGuidePageConfig(params);
      if (success) {
        message.success('保存成功');
      }
      context.emit('saveSuccess');
    };

    return {
      save,
      getGuidePageConfig,
      ...toRefs(formState)
    };
  }
});
</script>

<style lang="less" scoped>
.guide-page {
  padding: 15px 20px;
  .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .ant-form {
    margin-left: 30px;
  }
}
</style>
